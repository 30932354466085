(function () {
'use strict';

function Quote(text, author) {
  this.text = text;
  this.author = author;
}

const quotes = [
  new Quote('It works on my machine.', 'A colleague of mine'),
  new Quote('Testing is doubting.', 'Anonymous'),
  new Quote('Fuck it; we\'ll do it live.', 'Bill O\'Reilly'),
  new Quote(
    'Dance like no one is watching. Encrypt like everyone is.',
    'Anonymous'
  ),
  new Quote(
    'Only you can prevent forest fires.',
    'Me when a colleague ignores a compiler warning'
  ),
  new Quote('One person\'s WTF is another person\'s FTW.', 'Anonymous'),
  new Quote(
    'Tell me, and I will forget. Show me and I may remember. Involve me, and I will learn.',
    'Benjamin Franklin'
  ),
  new Quote('We never fail; either we succeed or we learn.', 'Anonymous'),
  new Quote('Experiment. Fail. Learn. Repeat.', 'Anonymous'),
  new Quote(
    'Don\'t correct a fool, or he will hate you; correct a wise man, and he will ' +
      'appreciate you.',
    'Proverbs 9:8'
  ),
  new Quote(
    'Success is the ability to go from failure to failure without losing your enthusiasm.',
    'Winston Churchill'
  ),
  new Quote(
    'Everybody is a genius, but if you judge a fish by its ability to climb a tree, it ' +
      'will live is whole life believing it is stupid.',
    'Albert Einstein'
  ),
  new Quote(
    'The world is full of geniuses who believe they are dumb because they got bad scores ' +
      'on things they were supposed to be good at.',
    'Anonymous'
  ),
  new Quote('When you are about to quit, remember why you started.', 'Anonymous'),
  new Quote(
    'The problem with the world is that the intelligent people are full of doubts, while ' +
      'the stupid ones are full of confidence.',
     'Charles Bukowski'
  ),
  new Quote(
    'The greatest enemy of knowledge is not ignorance, it is the illusion of knowledge.',
    'Stephen Hawking'
  ),
  new Quote('Don\'t be afraid of failure. Be afraid of giving up.', 'Anonymous'),
];

// Returns a random integer between min (included) and max (included)
function getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

var randomIndex = getRandomIntInclusive(1, quotes.length);
var randomQuote = quotes[randomIndex - 1];

var quoteDiv = document.querySelector('div.quote-container div.quote');
quoteDiv.textContent = randomQuote.text;
var authorDiv = document.querySelector('div.quote-container div.author');
authorDiv.textContent = randomQuote.author;

}());
