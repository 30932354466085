(function () {
'use strict';

WebFont.load({
  google: {
    families: [
      'Sorts Mill Goudy:400italic',
      'Open Sans:300,300italic',
      'Oxygen Mono',
      'Raleway:100',
      'Ubuntu:700',
    ],
  },
});
}());
