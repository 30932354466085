(function () {
'use strict';

var isDigCommandFired = false;

var waypoint = new Waypoint({
  element: document.querySelector('#term .terminal-window'),
  offset: 'bottom-in-view',
  handler: function (direction) {
    if (!isDigCommandFired) {
      isDigCommandFired = true;

      var digCommand = document.querySelector('#term span.dig-command');
      digCommand.classList.add('animate');

      var cursor = document.querySelector('#term span.cursor');
      cursor.classList.remove('blinkingCursor');

      setTimeout(function () {
          cursor.classList.add('blinkingCursor');
        }, 2500 // value from @digCommandDuration in main.less
      );
    }
  },
});

new Waypoint({
  element: document.querySelector('footer .fa-code'),
  offset: 'bottom-in-view',
  handler: function (direction) {
    var digCommand = document.querySelector('footer .fa-code');
    digCommand.classList.add('animate');
  },
});

}());
